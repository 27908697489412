<template>
    <div class="w-full">
        <a :href="props.advert.RedirectUrl" target="_blank" class="mx-auto block max-w-full">
            <img :src="advert.LargeBannerUrl" :alt="advert.ImageAlt" class="hidden md:block mx-auto max-w-full">
            <img :src="advert.SmallBannerUrl" :alt="advert.ImageAlt" class="block md:hidden mx-auto max-w-full">
        </a>
    </div>
</template>

<script setup>
    import Http from '@/js/global/http'

    const props = defineProps({
        advert: {
            type: Object,
            default: window.advert?.data ?? null
        }
    })

    if (window.advert.adStatistics.reported == false && props.advert.UId) {
        Http.post(window.advert.adStatistics.url, {
            data: {
                UId: props.advert.UId,
                location: props.advert.Location
            },
            success: () => {
                window.advert.adStatistics.reported = true
            }
        })
    }

</script>
